(function() {
    angular.module('EntrakV5').controller('appController', appController);

    var firebase = require('firebase/app');
    require('firebase/analytics');

    firebase.initializeApp({
        apiKey: "AIzaSyBiHD3zUS48GCvGtgnCIJck686L0loIBwo",
        authDomain: "en-trak-portal.firebaseapp.com",
        databaseURL: "https://en-trak-portal.firebaseio.com",
        projectId: "en-trak-portal",
        storageBucket: "",
        messagingSenderId: "101733801519",
        appId: "1:101733801519:web:5d5b781ca0ddafd6",
        measurementId: "G-SJCTTCWCK7"
    });
    firebase.analytics();

    function appController($scope, $rootScope, $q, $timeout, $transitions, $state, Service, KEY, APIKEY, Api, LOGIN_URL, IS_LOCAL) {
        console.log('appController');
        var caller = null;
        //global value
        // $rootScope.langCode
        // $rootScope.previousState
        // $rootScope.currentState
        $rootScope.currentUser = null;
        $rootScope.isEditing = false;
        $rootScope.noLoginStates = ['landing', 'toApp'];
        $rootScope.dashboardAdminStates = ['insight'];
        $rootScope.nonAdminStates = ['dashboard', 'newDashboard', 'profile'];
        $rootScope.adminStates = ['calendar'];
        $rootScope.zoneAdminStates = ['schedule', 'selectFloor', 'location', 'log', 'scene', 'account', 'selectHeatmap', 'heatmap'];
        $rootScope.tepOnlyStates = ['dashboard', 'schedule', 'calendar', 'selectFloor', 'location', 'log', 'scene', 'selectHeatmap', 'heatmap'];
        $rootScope.defaultTimerDuration = KEY.timerDefault;
        $rootScope.maxTimerHour = KEY.defaultTimerMaxHr;
        $rootScope.title = null;
        $rootScope.currentState = '';
        $rootScope.currentParams = {};
        $rootScope.searchPlaceholder = Service.translate("label.search");
        $rootScope.loadingPage = 0;
        // $rootScope.loginMethod = 0;//email=1, ms oauth=2
        $rootScope.managedZoneMap = {};
        $rootScope.signInMethod = null;
        $rootScope.idToken = null;
        //global value

        //global constant
        // $rootScope.tenantHasScene = KEY.tenantHasScene;
        $rootScope.NODE_TYPE = APIKEY.nodeType;
        $rootScope.NODE_TYPE_INV = APIKEY.nodeTypeInv;
        $rootScope.APPLICATION_TYPE = APIKEY.applicationType;
        $rootScope.APPLICATION_TYPE_INV = APIKEY.applicationTypeInv;
        $rootScope.ACTION = APIKEY.action;
        $rootScope.ROLE = APIKEY.role;
        $rootScope.ACL_ROLE = APIKEY.aclRole;
        $rootScope.colorList = ["#91dc3b", "#3aaf01", "#18ce81", "#a7edd0", "#55e8cc", "#709e94"];
        //global constant

        //global function
        $rootScope.getTenantId = function() {
            return $scope.tenantIdPromise;
        }

        $rootScope.hasTep = function(user) {
          return (user || $rootScope.currentUser)?.tenant?.enableTep;
        }
        $rootScope.hasEms = function(user) {
          return (user || $rootScope.currentUser)?.tenant?.enableEms;
        }

        $rootScope.hasMinAclRole = function(minRole, user) {
          return Service.hasMinAclRole(user || $rootScope.currentUser, minRole);
        }
        $rootScope.getAclRoleLv = function(role) {
          return Service.getAclRoleLv(role);
        }
        $rootScope.getUserAclRoleLv = function(user) {
          return Service.getUserAclRoleLv(user || $rootScope.currentUser);
        }
        $rootScope.isAclRole = function(role, user) {
          return Service.isAclRole(user || $rootScope.currentUser, role);
        }

        $rootScope.toggleAdminMenu = function(show) {
            if (show != $scope.showAdminMenu) {
                $scope.showAdminMenu = show;
                $rootScope.$broadcast('toggleSideMenu', show);
            }
        }

        $scope.errorNotfOpt = {
            position: {
                right: 10,
                bottom: 10
            },
            width: 320,
            autoHideAfter: 8000,
        }
        $rootScope.showError = function(msg) {
            $scope.errorNotf.show(msg, "error");
        }

        $rootScope.generalErrorHandler = function(skipLoadCount) {
            if (skipLoadCount !== true)
                $rootScope.loadingPage--;
            $rootScope.showError(Service.translate("error.general"));
        }

        $rootScope.setEditing = function(isEditing) {
            $timeout(function() {
                $rootScope.isEditing = isEditing;
            });
        }

        $rootScope.haveReadingType = function(applicationType){
            return applicationType === APIKEY.applicationType.aircon || applicationType === APIKEY.applicationType.iaqSensor || applicationType === APIKEY.applicationType.lightSensor || applicationType === APIKEY.applicationType.thermometer;
        }
        $rootScope.noSceneType = function(applicationType){
            return applicationType === APIKEY.applicationType.button || applicationType === APIKEY.applicationType.waterMeter || applicationType === APIKEY.applicationType.energyMeter || applicationType === APIKEY.applicationType.motionSensor || applicationType === APIKEY.applicationType.iaqSensor || applicationType === APIKEY.applicationType.door || applicationType === APIKEY.applicationType.unknown;
        }

        // $rootScope.needChangePwd = function(user){
        //     return user && $rootScope.loginMethod == 1 && user.tenant.passwordPolicy === APIKEY.pwdPolicy.advanced && user.passwordExpired;
        // }

        $rootScope.topTooltipOpt = {
            callout: false,
            position: "top",
            filter: ".topTooltip",
            content: function(e) {
                return Service.translate(e.target.data("title")); // the element for which the tooltip is shown
            }
        }

        /* disconnect window */
        $scope.disconnectWinData = {
            node: null,
        }
        $scope.disconnectWinOpt = {
            title: Service.translate("disconnectWin.title"),
            width: "700px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"],
        }
        $rootScope.showDisconnectWin = function(node) {
            $scope.disconnectWinData.node = node;
            setTimeout(function() {
                $scope.disconnectWin.center().open();
            });
        }
        /* disconnect window */

        /* delete window */
        $scope.deleteWinOpt = {
            width: "600px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: [],
            deactivate: function() {
                $scope._deleteWin = {};
            }
        }
        $scope._deleteWin = {
            desc: null,
            displayName: null,
            deleteFunc: null,
            deleting: false
        }
        $rootScope.deletePopup = {
            show: function(titleCode, descCode, displayName, deleteFunc) {
                $scope.deleteWin.title(Service.translate(titleCode));
                $scope._deleteWin = {
                    desc: Service.translate(descCode),
                    displayName: displayName,
                    deleteFunc: function() {
                        $scope._deleteWin.deleting = true;
                        if (deleteFunc)
                            deleteFunc();
                    },
                    deleting: false
                }
                setTimeout(function() {
                    $scope.deleteWin.open().center();
                });
            },
            close: function() {
                $scope.deleteWin.close();
            }
        }
        /* delete window */

        /* select ws window */
        $scope.selectWsWinOpt = {
            width: "800px",
            height: "450px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false
        }
        $scope._selectWsData = {
            selectWsList: [],
            showWsList: false,
            id: null,
            curWs: null,
            saving: 0,
            callback: null,
            desc: ""
        }
        $scope.hotDeskSort = function(ws){
            return [!ws.isHotDesk, ws.name];
        }
        $rootScope.showSelectWsWin = function(wsList, curWs, desc, callback){
            $scope._selectWsData.selectWsList = wsList;
            $scope._selectWsData.showWsList = false;
            $scope._selectWsData.id = null;
            $scope._selectWsData.curWs = curWs;
            $scope._selectWsData.desc = desc;
            $scope._selectWsData.callback = callback;
            $scope.selectWsWin.title("");
            setTimeout(function(){
                $scope.selectWsWin.center().open();
            });
        }
        $rootScope.isSavingSelectWsWin = function(){
            return $scope._selectWsData.saving > 0;
        }
        $scope.showWsListView = function(){
            $scope.selectWsWin.title(Service.translate("dashboard.selectWsWin.selectWsDesc"));
            $scope._selectWsData.showWsList = true;
        }
        $scope.selectWorkstation = function(id){
            $scope._selectWsData.id = id;
        }
        $scope.confirmSelectWorkstation = function(){
            $rootScope.loadingPage += 2;
            $scope._selectWsData.saving += 2;
            var ttId = null;
            var isLast = false;
            function processLastRes(res){
                if (res.id == $scope._selectWsData.id){
                    if (res.timetableId)
                        ttId = res.timetableId;
                    if (!isLast){
                        isLast = true;
                    } else {
                        res.timetableId = ttId;
                        $scope._selectWsData.callback(res);
                        $scope.selectWsWin.close();
                    }
                }
                $scope._selectWsData.saving--;
                $rootScope.loadingPage--;
            }
            function errorHandler(err){
                if (err === KEY.ignore)
                    return;
                $scope._selectWsData.saving--;
                $rootScope.generalErrorHandler();
            }
            caller.call(Api.turnOnWorkstation($scope._selectWsData.id), true).then(processLastRes, errorHandler);
            caller.call(Api.selectWorkstation($scope._selectWsData.id), true).then(processLastRes, errorHandler);
            if ($scope._selectWsData.curWs && $scope._selectWsData.curWs.status){
                caller.call(Api.turnOffWorkstation($scope._selectWsData.curWs.id), true).then(null, function(){
                    $rootScope.generalErrorHandler(true);
                });
            }
        }
        /* select ws window */

        /* delegate ws window */
        $scope.delegateWsWinOpt = {
            title: Service.translate("delegateWsWin.title"),
            width: "740px",
            modal: true,
            draggable: false,
            visible: false,
            resizable: false,
            actions: ["Close"]
        }
        $scope.delegateUserSelectOpt = {
            autoWidth: true,
            autoClose: false,
            clearButton: false,
            dataSource: [],
            dataTextField: "email",
            dataValueField: "id"
        }
        $scope._delegateWsData = {
            users: [],
            initSelections: [],
            curSelections: [],
            wsId: null,
            wsName: '',
            saving: 0,
            callback: null
        }
        $rootScope.showDelegateWsWin = function(wsId, wsName, users, selectedUserIds, callback){
            $scope._delegateWsData.wsId = wsId;
            $scope._delegateWsData.wsName = wsName;
            $scope._delegateWsData.initSelections = selectedUserIds;
            $scope._delegateWsData.callback = callback;
            $scope._delegateWsData.users = users;
            $scope.delegateUserSelect.setDataSource(new kendo.data.DataSource({
                data: users
            }));//need set dataSource first, otherwise may have bug
            $scope._delegateWsData.curSelections = [...selectedUserIds];
            setTimeout(function() {
                $scope.delegateWsWin.center().open();
            });
        }
        $rootScope.isSavingDelegateWsWin = function(){
            return $scope._delegateWsData.saving > 0;
        }
        $scope._saveDelegate = function(){
            var addIds = [...$scope._delegateWsData.curSelections];
            var removeIds = [];
            for (var i=$scope._delegateWsData.initSelections.length-1; i>=0; i--){
                var index = addIds.indexOf($scope._delegateWsData.initSelections[i]);
                if (index != -1){
                    addIds.splice(index, 1);
                } else {
                    removeIds.push($scope._delegateWsData.initSelections[i]);
                }
            }
            $scope._delegateWsData.saving = addIds.length + removeIds.length;

            var calls = [];
            var resSelections = [...$scope._delegateWsData.initSelections];
            addIds.forEach(function(id){
                calls.push(caller.call(Api.createDelegation($scope._delegateWsData.wsId, id), true).then(function(res){
                    resSelections.push(id);
                    $scope._delegateWsData.saving--;
                }, function(err){
                    $rootScope.showError(Service.translate("error.generalAddDelegateFail", { email: Service.getArrItem($scope._delegateWsData.users, id).email }));
                    $scope._delegateWsData.saving--;
                }));
            });
            removeIds.forEach(function(id){
                calls.push(caller.call(Api.deleteDelegation($scope._delegateWsData.wsId, id), true).then(function(res){
                    resSelections.splice(resSelections.indexOf(id), 1);
                    $scope._delegateWsData.saving--;
                }, function(err){
                    $rootScope.showError(Service.translate("error.generalRemoveDelegateFail", { email: Service.getArrItem($scope._delegateWsData.users, id).email }));
                    $scope._delegateWsData.saving--;
                }));
            });
            if (calls.length){//popup will close anyway, dont need to update initSelections and curSelections
                Promise.allSettled(calls).then(function(){
                    $scope.delegateWsWin.close();
                    $scope.$apply(function(){
                        $scope._delegateWsData.callback(resSelections.map(function(id){
                            return { userId: id };
                        }));
                    });
                });
            } else {
                console.log("not change");
                $scope.delegateWsWin.close();
            }
        }
        /* delegate ws window */

        $rootScope.isCheckedIn = Service.isCheckedIn;
        $rootScope.isWorkstation = Service.isWorkstation;

        /* color list */
        var colorIndex = -1;
        $rootScope.resetColorIndex = function() {
            colorIndex = -1;
        }
        $rootScope.getNextColor = function() {
            colorIndex++;
            if (colorIndex >= $rootScope.colorList.length)
                colorIndex = 0;
            return $rootScope.colorList[colorIndex];
        }
        /* color list */
        //global function

        //news
        Service.storageDelete("READ_BOOKMARK_NEWS");
        // $scope.hasNews = !Service.storageGet("READ_BOOKMARK_NEWS");
        // $scope.closeNews = function(){
        //     $scope.hasNews = false;
        //     Service.storageSave("READ_BOOKMARK_NEWS", 1);
        // }
        //news

        //header
        $scope.langDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("header.en"),
                short: Service.translate("header.enShort"),
                value: 'en'
            }, {
                text: Service.translate("header.zh"),
                short: Service.translate("header.zhShort"),
                value: 'zh'
            }, {
                text: Service.translate("header.cn"),
                short: Service.translate("header.cnShort"),
                value: 'cn'
            }],
            // value: $rootScope.langCode,
            dataTextField: "text",
            dataValueField: "value",
            valueTemplate: '#=data.short#',
            template: '#=data.text#',
            select: function(e) {
                Service.setLangCode(e.dataItem.value);
                window.location.reload();
            }
        }
        $scope.userDropdownOpt = {
            autoWidth: true,
            dataSource: [{
                text: Service.translate("profile.title"),
                value: 'profile'
            }, {
                text: Service.translate("header.logout"),
                value: 'logout'
            }],
            dataTextField: "text",
            dataValueField: "value",
            valueTemplate: '<div class="p-3"></div>',
            template: '#=data.text#',
            select: function(e) {
                if (e.dataItem.value === 'logout') {
                    Api.logout();
                } else if (e.dataItem.value === 'profile') {
                    $state.go('profile');
                }
            }
        }
        $scope.tenantDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id",
            change: function(){
                var id = this.value();
                if ($rootScope.currentUser && id != $rootScope.currentUser.tenant.id) {
                    caller.call(Api.changeProfileTenant(id)).then(function(){
                        if ($rootScope.currentState === 'dashboard'){
                            window.location.reload();
                        } else {
                            window.location.href = window.location.href.split($rootScope.currentState)[0];
                            window.location.reload();
                        }
                    });
                }
            }
        }
        //header

        //boardcast
        $scope.hasSideMenu = false;
        $scope.showAdminMenu = false;
        $scope.$watchCollection('[$root.currentState, $root.isEditing]', function(newArr, oldArr) {
            var tmp = $rootScope.noLoginStates.indexOf(newArr[0]) == -1 && !newArr[1];
            // && (newArr[1] === APIKEY.role.admin || newArr[1] === APIKEY.role.super || newArr[1] === APIKEY.role.zoneAdmin) && newArr[0] != 'profile' 
            if (tmp != $scope.hasSideMenu) {
                $scope.hasSideMenu = tmp;
                $rootScope.$broadcast('toggleSideMenu', tmp);
            }
            $rootScope.toggleAdminMenu(false);
        });
        //boardcast

        //click overlay to close kendo window
        $(document).on('click', '.k-overlay', function() {
            var kendoWindow = $('.k-window-content.k-content', $(this).next('div.k-widget.k-window'));
            if (kendoWindow == null || kendoWindow.length == 0) {
                return;
            }
            kendoWindow.data('kendoWindow').close();
        });
        //click overlay to close kendo window

        //transition
        $transitions.onStart({}, function(trans) {
            console.log('start: ' + trans.from().name + " -> " + trans.to().name);
            $rootScope.title = null;

            var fromState = trans.from().name;
            var toState = trans.to().name;
            var isFirstLoad = ($rootScope.noLoginStates.indexOf(fromState) != -1 || fromState === '');

            // if $rootScope.currentUser obj is not set yet, skip checking now and do redirect in getCurrentUser callback
            if ($rootScope.currentUser){
                if (!$rootScope.hasTep() && $rootScope.tepOnlyStates.indexOf(toState) > -1) {
                    console.error('only tep user can access this page', toState);
                    return false;
                }
                if ($rootScope.dashboardAdminStates.indexOf(toState) > -1) {
                  if (!$rootScope.isAclRole(APIKEY.aclRole.dashboardAdmin)) {
                      console.error('you cannot access this page', toState);
                      return false;
                  }
                } else {
                  var userLv = $rootScope.getUserAclRoleLv();
                  if ($rootScope.getAclRoleLv(APIKEY.aclRole.zoneAdmin) == userLv) {
                    if ($rootScope.zoneAdminStates.indexOf(toState) == -1 && $rootScope.nonAdminStates.indexOf(toState) == -1 && $rootScope.noLoginStates.indexOf(toState) == -1) {
                        console.error('only admin can access', toState);
                        return false;    
                    }                    
                  } else if ($rootScope.getAclRoleLv(APIKEY.aclRole.tenantUser) >= userLv) {
                    if ($rootScope.nonAdminStates.indexOf(toState) == -1 && $rootScope.noLoginStates.indexOf(toState) == -1) {
                        console.error('only admin can access', toState);
                        return false;
                    }
                  }
                }
            }

            // for force change pwd
            // if ($rootScope.needChangePwd($rootScope.currentUser)){
            //     if (toState != 'profile')
            //         return false;
            // }

            $rootScope.loadingPage = 0;
            if (isFirstLoad && $rootScope.noLoginStates.indexOf(toState) == -1) {
                $scope.loadingUser = true;
                //dev and prod should have cookie, localhost will need to get it from landing page
                if (IS_LOCAL) {
                  var m = Service.storageGet("et_method");
                  var t = Service.storageGet("et_it");
                } else {
                  var m = Service.getCookie("et_method");
                  var t = Service.getCookie("et_it");
                }
                if (m) {
                  $rootScope.signInMethod = m;
                  $rootScope.idToken = t;
                }
                if (!$rootScope.signInMethod)
                  window.location = LOGIN_URL;
                Api.initApolloClient($rootScope.signInMethod, $rootScope.idToken, true);
                if (!caller)
                  caller = Api.createApiCaller();
                $scope.tenantIdPromise = caller.call(Api.getCurrentUser()).then(function(res) {
                    var userRes = res;
                    $scope.loadingUser = false;
                    $rootScope.currentUser = userRes;
                    $rootScope.defaultTimerDuration = userRes.tenant.defaultExtensionMinutes;
                    $rootScope.maxTimerHour = userRes.tenant.maxExtensionHours;
                    $rootScope.managedZoneMap = {};
                    userRes.managedZones.forEach(z => $rootScope.managedZoneMap[z.zoneId] = true);

                    if ($rootScope.getUserAclRoleLv(userRes) == -1 || !userRes.tenant || !userRes.tenant.id) {
                        alert(Service.translate("error.noPortalAccess"));
                        Api.logout();
                        return;
                    }

                    // for force change pwd
                    // if ($rootScope.needChangePwd(userRes))
                    //     $state.go('profile');

                    if ($rootScope.isAclRole(APIKEY.aclRole.super, userRes)) {
                        $scope.loadingUser = true;
                        caller.call(Api.getTenants()).then(function(tenants){
                            var ddList = $("#tenantDropdownId").data("kendoDropDownList");
                            tenants.sort(Service.getSorter());
                            ddList.setDataSource(new kendo.data.DataSource({ data: tenants }));
                            ddList.value(userRes.tenant.id);
                            $scope.loadingUser = false;
                        }, function(err){
                            $scope.loadingUser = false;
                            console.error('cannot get tenants', err);
                            alert(Service.translate("error.general"));
                        });
                    }

                    if (!$rootScope.hasTep(userRes) && $rootScope.tepOnlyStates.indexOf(toState) > -1) {
                        console.error('only tep user can access this page', toState);
                        $state.go('dashboard');
                    }
                    if ($rootScope.dashboardAdminStates.indexOf(toState) > -1) {
                        if (!$rootScope.isAclRole(APIKEY.aclRole.dashboardAdmin, userRes)) {
                            console.error('you cannot access this page, redirecting to dashboard' + rootScope.currentState);
                            $state.go('dashboard');
                        }
                    } else {
                      var userLv = $rootScope.getUserAclRoleLv();
                      if ($rootScope.getAclRoleLv(APIKEY.aclRole.zoneAdmin) == userLv) {
                        if ($rootScope.zoneAdminStates.indexOf(toState) == -1 && $rootScope.nonAdminStates.indexOf(toState) == -1) {
                            console.error('redirecting to dashboard, only admin can access ' + $rootScope.currentState);
                            $state.go('dashboard');
                        }
                      } else if ($rootScope.getAclRoleLv(APIKEY.aclRole.tenantUser) >= userLv) {
                          if ($rootScope.nonAdminStates.indexOf(toState) == -1) {
                            console.error('redirecting to dashboard, only admin can access ' + $rootScope.currentState);
                            $state.go('dashboard');
                          }
                      }
                    }

                    return userRes.tenant.id;
                }, function(err) {
                    $scope.loadingUser = false;
                    $rootScope.generalErrorHandler(true);
                });
            }
        });

        $transitions.onSuccess({}, function(trans) {
            console.log('success: ', $rootScope.previousState, $rootScope.previousParams, $rootScope.currentState, $rootScope.currentParams);
            $rootScope.previousState = $rootScope.currentState;
            $rootScope.currentState = trans.to().name;
            $rootScope.previousParams = $rootScope.currentParams;
            $rootScope.currentParams = trans.params();
            $rootScope.isEditing = false;
        });
        //transition

        $scope.$on('$destroy', function() {
            console.log("appController destroy");
        });
    }
})();
